<template>
  <div :class="allowDelete ? 'allow-delete group-enumerator-row txt-black' : 'disallow-delete group-enumerator-row txt-black'">
    <div class="editable" @click="onViewEnumerator">
      <div class="flex align-center">
        {{ enumeratorProfile.firstName }} {{ enumeratorProfile.familyName }}
        <a-tooltip
          v-if="enumeratorProfile.supervisor"
          class="ml-8"
          :title="$t('components.toolTips.supervisorRole')">
          <a-icon type="star" theme="filled" style="color: rgb(249 218 130); font-size: 18px"/>
        </a-tooltip>
      </div>
      <EnumeratorProfile
        v-if="enumeratorProfile && viewing"
        :has-supervisor="hasSupervisor"
        :on-enumerator-update="onEnumeratorUpdate"
        :on-exit="onViewEnumerator"
        :on-updated="onUpdated"
        :enumerator="enumeratorProfile"/>
    </div>
    <div>
      <StandardizationOutcome v-if="showStandardizationOutcome" :enumerator="enumeratorProfile" property="height"/>
    </div>
    <div>
      <StandardizationOutcome v-if="showStandardizationOutcome" :enumerator="enumeratorProfile" property="weight"/>
    </div>
    <div>
      <StandardizationOutcome v-if="showStandardizationOutcome" :enumerator="enumeratorProfile" property="muac"/>
    </div>
    <div>
    </div>
    <div v-if="allowDelete && !isSupervisor" class="flex-end">
      <a-popconfirm
        :title="$t('components.notifications.areYouSureYouWantToDeleteEnumerator')"
        :ok-text="$t('values.yes')"
        :cancel-text="$t('values.no')"
        :disabled="isSupervisor"
        @confirm="onDelete(enumeratorProfile)">
        <a-icon type="delete" theme="filled" style="color: rgb(255 68 68); font-size: 18px"/>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EnumeratorItem',
  components: {
    EnumeratorProfile: () => import('../../EnumeratorProfile/enumerator-profile.vue'),
    StandardizationOutcome: () => import('../../StandardizationOutcome/standardization-outcome.vue')
  },
  props: {
    enumerator: {
      type: Object,
      required: true
    },
    showStandardizationOutcome: {
      type: Boolean,
      required: true
    },
    allowDelete: {
      type: Boolean,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    },
    onUpdated: {
      type: Function,
      required: true
    },
    hasSupervisor: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      viewing: false,
      enumeratorProfile: this.enumerator
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    enumerator(newValue) {
      this.enumeratorProfile = newValue;
    }
  },
  methods: {
    ...mapActions([
      'getStandardizationGroupsForSurvey'
    ]),
    onEnumeratorUpdate(enumeratorIndex, teamIndex, updatedEnumerator) {
      this.enumeratorProfile = updatedEnumerator;
    },
    onViewEnumerator() {
      this.viewing = !this.viewing;
    }
  }
};
</script>

<style lang="scss" scoped>
.editable {
  cursor: pointer;
  text-decoration: underline;
}
.flex-end {
  display: flex;
  justify-content: flex-end;

  i {
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
